export enum ValidatorsList {
  isString = 'isString',
  isEmail = 'isEmail',
  notEmpty = 'notEmpty',
}

export type ValidatorName =
  | typeof ValidatorsList.isEmail
  | typeof ValidatorsList.isString
  | typeof ValidatorsList.notEmpty;

export type ValidatorFunction = (value: unknown) => boolean;
export type ExeptionMessage = (label?: string) => string;

export interface IValidator {
  VALIDATOR_NAME: string;
  validate(value: string): IValidationResult;
}

export interface IValidationResult {
  isValid: boolean;
  validators: {
    [key: string]: boolean;
  };
}
