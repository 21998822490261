import { AbstractValidator } from './AbstractValidator';
import { IValidator } from './model';

export class Between extends AbstractValidator implements IValidator {
  readonly VALIDATOR_NAME: string = 'between';
  protected a: number;
  protected b: number;

  constructor(a: number, b: number) {
    super();
    this.a = a;
    this.b = b;
  }

  protected validationFn(value: string): boolean {
    const length = value.replace(/\s+/g, '').length;
    return length >= this.a && length <= this.b;
  }
}

export default function(a: number, b: number) {
  return new Between(a, b);
}
