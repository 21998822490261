import AbstractValidatorCombiner from '@/services/validators/combiners/AbstractValidatorCombiner';
import { IValidator } from '@/services/validators/model';

export class ValidatorCombinerOr extends AbstractValidatorCombiner implements IValidator {
  VALIDATOR_NAME = 'orCombiner';

  public validate(value: string) {
    while (this.haveMoreValidators()) {
      const validator = this.getNextValidator();
      const result = validator.validate(value);

      this.mergeValidatorsResults(result);

      if (this.currentIndex === 0) this.lastValidation = result.isValid;
      if (this.currentIndex > 0) this.lastValidation = this.lastValidation || result.isValid;

      if (this.lastValidation) return this.getCombinerResult();
    }

    return this.getCombinerResult();
  }
}

export default function(validators: Array<IValidator>, name?: string) {
  return new ValidatorCombinerOr(validators, name);
}
