import { AbstractValidator } from './AbstractValidator';
import { IValidator } from './model';

export class EmailValidator extends AbstractValidator implements IValidator {
  readonly VALIDATOR_NAME: string = 'email';

  protected validationFn(value: string): boolean {
    // eslint-disable-next-line
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  }
}

export default new EmailValidator();
