import { IValidationResult, IValidator } from '@/services/validators/model';

export default abstract class AbstractValidatorCombiner {
  VALIDATOR_NAME = 'abstractCombiner';

  validators: Array<IValidator> = [];
  currentIndex = -1;
  lastValidation: boolean;
  validationResults: { [key: string]: boolean } = {};

  constructor(validators: Array<IValidator>, name?: string) {
    if (name) this.VALIDATOR_NAME = name;
    this.validators = validators;
  }

  protected mergeValidatorsResults(result: IValidationResult) {
    this.validationResults = { ...this.validationResults, ...result.validators };
  }

  protected haveMoreValidators(): boolean {
    return this.currentIndex < this.validators.length - 1;
  }

  protected getNextValidator(): IValidator {
    this.currentIndex++;
    return this.validators[this.currentIndex];
  }

  protected getCombinerResult(): IValidationResult {
    this.currentIndex = -1;
    this.validationResults[this.VALIDATOR_NAME] = this.lastValidation;

    return {
      isValid: this.lastValidation,
      validators: this.validationResults,
    };
  }
}
