import { AbstractValidator } from './AbstractValidator';
import { IValidator } from './model';

export class Required extends AbstractValidator implements IValidator {
  readonly VALIDATOR_NAME: string = 'required';

  protected validationFn(value: string): boolean {
    return value.replace(/\s+/g, '') !== '';
  }
}

export default new Required();
