import { IValidationResult } from '@/services/validators/model';

export class AbstractValidator {
  readonly VALIDATOR_NAME: string = 'abstractValidator';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected validationFn(value: string): boolean {
    throw new Error('You must implement validation logic in validator class!');
  }

  public validate(value: string): IValidationResult {
    const isValid = this.validationFn(value);

    return {
      isValid,
      validators: {
        [this.VALIDATOR_NAME]: isValid,
      },
    };
  }
}
