import { AbstractValidator } from './AbstractValidator';
import { IValidator } from './model';

export class Empty extends AbstractValidator implements IValidator {
  readonly VALIDATOR_NAME: string = 'empty';

  protected validationFn(value: string): boolean {
    return value.replace(/\s+/g, '') === '';
  }
}

export default new Empty();
